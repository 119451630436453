import React from 'react';
import PropTypes from 'prop-types';
import { WhatsApp } from '@oxygen/ab/WhatsApp';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'rc-slider/assets/index.css';
import 'react-phone-number-input/style.css';
import AuthenticationProvider from '@oxygen/ab/AuthenticationProvider';
import ThemeProvider from '@oxygen/ab/ThemeProvider';
import RemoveServerSideInjectedCss from '@oxygen/ab/RemoveServerSideInjectedCss';
import UtmTracker from '@oxygen/ab/UtmTracker';
import SentryTracker from '@oxygen/ab/SentryTracker';
import FaviconsMeta from '@oxygen/ab/FaviconsMeta';
import seoConfig from '@oxygen/ab/SeoConfig';
import GlobalStyles from '@oxygen/ab/GlobalStyles';
import PageTransitionIndicator from '@oxygen/ab/PageTransitionIndicator';
import { DefaultSeo } from 'next-seo';
import CssBaseline from '@material-ui/core/CssBaseline';
import Head from 'next/head';
import { track } from '@oxygen/ab/Segment';
import { RoutingProvider } from '@oxygen/ab/RoutingProvider';
import QueryClientDefaultOptions from '@oxygen/ab/QueryClientDefaultOptions';

const queryClient = new QueryClient(QueryClientDefaultOptions);

function onStartWhatsappChat() {
  track('WhatsAppClicked', { source: 'Website' });
}

function App({ Component, err, pageProps, router }) {
  return (
    <QueryClientProvider client={queryClient}>
      <RoutingProvider router={router}>
        <AuthenticationProvider>
          <RemoveServerSideInjectedCss />
          <UtmTracker query={router.query} />
          <SentryTracker />
          <Head>
            <meta charSet='UTF-8' />
            <meta
              name='viewport'
              content='minimum-scale=1, initial-scale=1, width=device-width'
            />
            <FaviconsMeta />
            <link rel='preconnect' href='https://fonts.googleapis.com' />
            <link
              rel='preconnect'
              href='https://fonts.gstatic.com'
              crossOrigin='true'
            />
            <link
              href='https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:wght@400;600;700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap'
              rel='stylesheet'
            />
            <FaviconsMeta />
          </Head>
          <DefaultSeo {...seoConfig} />
          <CssBaseline />
          <GlobalStyles />
          <ThemeProvider>
            <Component {...pageProps} err={err} />
          </ThemeProvider>
          <WhatsApp onStartWhatsappChat={onStartWhatsappChat} />
          <PageTransitionIndicator />
        </AuthenticationProvider>
      </RoutingProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  err: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
  router: PropTypes.object
};

export default App;
